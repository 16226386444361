<template>
  <div>
    出仓
  </div>
</template>
<script>
export default {
  name:'outWarehousing',
  data() {
    return {
      
    }
  },
}
</script>
<style lang="less" scoped>

</style>
